import React from 'react';
import { Jumbotron } from '../../components/Jumbotron/Jumbotron';

export function FrontPage() {
    return (
        <>
            <Jumbotron title="JavaZone"
                       subTitle="January 20th - 21th 2021"
                       location="Oslo Spektrum" />
        </>
    )
}
